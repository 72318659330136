<template>
  <v-text-field
    v-model="input"
    :counter="counter"
    :hint="hint"
    :label="label"
    :loading="loading"
    :mask="mask"
    :prepend-icon="prependIcon"
    :required="required"
    :rules="rules"
    persistent-hint
    clearable
    box
    validate-on-blur
  />
</template>

<script>
import { createGetParams } from '@/rest'
import inputControl from '@/mixins/input-control'

export default {
  mixins: [inputControl],
  props: {
    activeOnly: {
      type: Boolean,
      default: false,
    },
    existing: {
      type: Boolean,
      default: false,
    },
    noBlack: {
      type: Boolean,
      default: false,
    },
    noPrepaid: {
      type: Boolean,
      default: false,
    },
    noDealer: {
      type: Boolean,
      default: false,
    },
    noSelf: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    ugu: {
      type: Boolean,
      default: false,
    },
    searchNetwork: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      counter: null,
      hint: '',
      inputError: null,
      inputTimeout: null,
      loading: false,
      longMask: '#### ### ####',
      shortMask: '### ### ####',
      source: null,
    }
  },
  computed: {
    mask: function () {
      if (!this.input) {
        return this.shortMask
      }

      const mask = this.input.startsWith('011') ? this.longMask : this.input.startsWith('01') ? this.shortMask
        : (!this.input.startsWith('0') || (!this.input.startsWith('01') && this.input.length > 1))
          ? '01# ### ####' : this.shortMask
      return mask
    },
    prependIcon: function () {
      return this.ugu ? 'phonelink_ring' : 'mdi-cellphone'
    },
  },
  watch: {
    mask: function (val) {
      if (!this.input) {
        this.counter = 10
      }

      this.counter = this.mask === this.longMask ? 11 : 10
    },
    input: function (val) {
      this.cancelRequest()
      if (val && ((this.mask === this.longMask && val.length === 11) ||
        (this.mask === this.shortMask && val.length === 10))) {
        this.verifyMsisdn()
      } else {
        this.validate()
        this.hint = ''
        this.loading = false
      }
    },
  },
  mounted: function () {
    if (this.rules) {
      const localRules = this.rules
      localRules.push(val => this.validateMsisdn(val))
      this.$emit('update:rules', localRules)
    }
  },
  methods: {
    cancelRequest: function () {
      this.inputError = null
      if (this.source) {
        this.source.cancel('Operation canceled by the user.')
        this.source = null
      }
    },
    validateMsisdn: function (val) {
      if (!val) {
        if (this.required) return 'Msisdn required!'
        return true
      }

      if ((val.startsWith('011') && val.length < 11) || val.length < 10) { return 'Invalid Msisdn!' }

      return !this.inputError || this.inputError
    },
    verifyMsisdn: function () {
      const url = this.searchNetwork ? 'getProfileFromNetwork.php' : 'getProfile.php'
      const params = createGetParams({
        target: this.input,
      })
      this.source = this.$rest.CancelToken.source()
      Object.assign(params, { cancelToken: this.source.token })
      this.loading = true
      this.$rest.get(url, params)
        .then(response => {
          if (response.data.msisdn) {
            if (this.existing) {
              if (this.noDealer && response.data.dealer_status === 'active') {
                this.inputError = 'Dealer Msisdn not allowed!'
                this.validate(true)
              } else if (this.noSelf && response.data.self) {
                this.inputError = 'Self Msisdn not allowed!'
                this.validate(true)
              } else if (this.noBlack && response.data.black) {
                this.inputError = 'Present account is Black!'
                this.validate(true)
              } else if (this.noPrepaid && !response.data.black) {
                this.inputError = 'Present account is prepaid!'
                this.validate(true)
              } else if (this.activeOnly && response.data.lineStatus != null && Number(response.data.lineStatus) !== 1) {
                const lineStatus = Number(response.data.lineStatus)
                switch (lineStatus) {
                  case 0:
                    this.inputError = 'Line terminated!'
                    break
                  case 2:
                    this.inputError = 'Line barred!'
                    break
                  case 3:
                    this.inputError = 'Line blocked!'
                    break
                  default:
                    this.inputError = 'Line unknown!'
                    break
                }
                this.validate(true)
              } else {
                this.hint = response.data.dealer_status === 'active' ? response.data.dealer + ': ' : ''
                this.hint += response.data.name
              }
            } else {
              this.inputError = 'Msisdn exists in OneSYS!'
              this.validate(true)
            } // else Success
          } else {
            this.inputError = 'Unexpected response!'
            this.validate(true)
          }
          this.loading = false
        }).catch(error => {
          if (this.$rest.isCancel(error)) {
            // ignore
          } else if (error.response) {
            if (error.response.status === 404) {
              if (this.existing) {
                this.inputError = 'No such Msisdn!'
                this.validate(true)
              } // else Success
            } else {
              this.inputError = error.message
              this.validate(true)
            }
          } else {
            this.inputError = error.message
            this.validate(true)
          }
          this.loading = false
        })
    },
  },
}
</script>
